.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  --dark-accent-color: #383529;
  --dark-color: #b68a03;
  --mid-color: #ffeaad;
  --light-color: #f9efd1;
  --red-color: rgb(250, 60, 60);
  --blue-color: rgb(100, 150, 255);
  --vis-tint: rgba(42, 40, 40, 0.7);
  --grass-color: #ccd44a;
  --info-box-color: #393726;

  /* Numbers define values that will be used throughout the CSS.
  --tile-size: 20px for example will set size of the grid squares. */
  --cols: 32;
  --rows: 32;
  --grid-margin: 2vh;
  --tile-size: calc(
    min((85vh - var(--grid-margin)) / var(--rows), (70vw - var(--grid-margin)) / var(--cols))
  );
  --tile-size-full: calc(
    min((100vh - var(--grid-margin)) / var(--rows), (70vw - var(--grid-margin)) / var(--cols))
  );
  --grid-size-hori: calc(var(--cols) * var(--tile-size));
  --grid-size-vert: calc(var(--rows) * var(--tile-size));
  --grid-padding-hori: calc((70vw - var(--grid-size-hori)) / 2);
  --grid-padding-vert: calc((85vh - var(--grid-size-vert)) / 2);
  --grid-padding-hori-full: calc((100vw - var(--grid-size-hori)) / 2);
  --grid-padding-vert-full: calc((100vh - var(--grid-size-vert)) / 2);
  --border-width: 0.2vmin;
}

.row-structure {
  display: flex;
  flex-direction: row-reverse;
}
