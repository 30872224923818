.sliderSettings {
  position: absolute;
  width: 30vw;
  height: 20vh;
  left: 70vw;
  top: 22vh;
  z-index: 100;
  background-color: var(--mid-color);
  color: var(--dark-color);
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  /* align-items: center; */
}

.info {
  font-family: 'Roboto', 'Helveltica Nue', sans-serif;
  color: var(--dark-color);
  font-size: 2vmin;
  margin: 1vh 2vw 1vh 3vw;
  float: left;
}

.info.bold {
  font-weight: bold;
}

.full {
  flex-basis: 1;
}

.info.win {
  margin: 1vh 1vw 1vh 1vw;
}

.info.stats {
  font-family: 'Earth Orbiter', 'Helveltica Nue', sans-serif;
  font-size: 2.5vmin;
  margin: 1vmin 0 1vmin 0;
}

/* Slider */
.slider {
  color: var(--dark-color);
  width: 25vw;
  height: 3vh;
  border-radius: 3px;
  padding: 0;
  margin: 1vh 0 1vh 0;
}

.slider:hover {
  color: var(--dark-color);
}

.MuiSlider-rail {
  color: var(--dark-color);
}

.MuiSlider-mark {
  color: var(--dark-color);
}

.MuiSlider-thumb {
  height: 3vmin;
  width: 1vmin;
  border-radius: 1px;
  background: var(--light-color);
}

.MuiSlider-thumb:hover {
  box-shadow: 0 0 0 6px #6639263b;
}

/* Icon play button */
.play-icon.MuiSvgIcon-root {
  color: var(--light-color);
  width: 4vmin;
  height: 4vmin;
}

.play-control.MuiButtonBase-root {
  background: var(--dark-color);
  transform: scale(0.9);
  width: 8vmin;
  height: 8vmin;
}

.play-control.MuiButtonBase-root:hover {
  background: var(--dark-accent-color);
  transform: scale(1);
}

.play-control.MuiButtonBase-root:active {
  background: none;
}

.play-control.MuiButtonBase-root:disabled {
  background: var(--dark-color);
  border: 1px solid var(--light-color);
  opacity: 70%;
}

/* Next and Previous Buttons */
.arrow {
  width: 4vmin;
  height: 4vmin;
  border-radius: 50%;
  border: none;
  margin: 1vmin;

  font-size: 2vmin;
  background-color: var(--dark-color);
  color: var(--light-color);

  transform: scale(0.9);
  transition-duration: 200ms;
  transition-property: background-color;
}

.arrow:hover {
  background: var(--dark-accent-color);
  transform: scale(1);
}

.arrow:active {
  background-color: var(--mid-color);
}

.arrow:disabled {
  background-color: var(--dark-color);
  border: 1px solid var(--light-color);
  opacity: 70%;
}

.arrow:disabled:hover {
  transform: scale(0.9);
}

/* Speed Selector */
.speed-select.MuiOutlinedInput-root {
  color: var(--light-color);
  background: var(--dark-color);
  width: 10vh;
  height: 6vh;
  font-size: 2vmin;
}

.speed-select.MuiOutlinedInput-root:hover,
.speed-select.MuiOutlinedInput-root.Mui-focused {
  background: var(--dark-accent-color);
}

.speed-select.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--dark-color);
}

.speed-select.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--dark-color);
}

.speed-select.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--dark-color);
}

.speed-select.MuiOutlinedInput-root.Mui-disabled {
  background: var(--dark-color);
  border: none;
  opacity: 70%;
}

.MuiSvgIcon-root.Mui-disabled {
  color: var(--light-color);
  opacity: 70%;
}

.MuiSvgIcon-root {
  color: var(--light-color);
}

.toggle-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 7vw;
  margin-top: 1vh;
}

.updateTest {
  position: fixed;
  top: 0;
  left: 0;
  background-color: pink;
}

.map {
  height: 85vh;
  width: 70vw;
  background: url('/src/assets/MapTiles/TileB2.png') repeat 0 0 fixed;
  background-size: var(--tile-size);
}

.map-info-box {
  font-family: 'Aldrich', 'Roboto', sans-serif;
  position: absolute;
  bottom: 0;
  width: 70vw;
  height: 15vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  background-color: var(--info-box-color);
}

.map-info-box div {
  flex-basis: 50%;
}

.info-box .info-badge {
  flex-basis: 30%;
  margin: 0;
  aspect-ratio: 1 / 1;
  width: 7vw;
}

.info-box .info-side {
  flex-basis: 70%;
  text-align: left;
  font-family: 'Aldrich', 'Roboto', sans-serif;
  display: inline-block;
  color: #fff;
}

.info-side div {
  display: flex;
  align-items: center;
  font-size: large;
}

.info-side label {
  margin: 0;
  font-size: 2.3vmin;
}

.info-box {
  display: flex;
  justify-content: center;
  height: 100%;
}

.info-badge {
  position: relative;
}

.info-coins {
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-box .info-badge .info-coins div {
  font-weight: bold;
  margin: auto;
  width: 70%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-box .info-badge .info-coins img {
  width: 50%;
  height: 50%;
  margin: 0;
}

.info-badge img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto;
}

progress {
  accent-color: white;
}

.health-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: 10;
}

.health-bar progress {
  /* border-radius: 15%; */
  /* border: 1px solid black; */
  position: absolute;
  top: -50%;
  left: 0;
  width: 100%;
  height: 100%;
}

.board {
  display: grid;
  grid-template-columns: repeat(var(--cols), var(--tile-size));
  grid-gap: 0;
  align-items: center;
  border: none;
  /* padding: 0; */
  /* border: calc(2 * var(--border-width)) solid var(--dark-color);*/
  margin: var(--grid-padding-vert) var(--grid-padding-hori) var(--grid-padding-vert) var(--grid-padding-hori) !important;
}

.board img {
  object-fit: cover;
}

.board.grid {
  position: relative;
}

.board.robot {
  position: absolute;
  z-index: 2;
}

.board.build {
  position: absolute;
  z-index: 10;
}

.board.visibility {
  position: absolute;
  z-index: 3;
}

.board.trail {
  position: absolute;
  z-index: 1;
}

.grid-square {
  width: var(--tile-size);
  height: var(--tile-size);
  border: none;
  /* border: var(--border-width) solid var(--dark-color); */
  display: block;
  position: relative;
}

.grid-square.trail {
  opacity: 0.5;
}

.tile-div {
  width: var(--tile-size);
  height: var(--tile-size);
  border-style: none;
  display: flex;
  justify-content: center;
  position: relative;
}

.tileBgImg {
  width: 100%;
  height: 100%;
}

.grid-square.REDtint {
  /* background-color: rgba(249, 60, 60, 0.4); */
  background-color: var(--vis-tint);
}

.grid-square.BLUEtint {
  /* background-color: rgba(45, 45, 245, 0.4); */
  background-color: var(--vis-tint);
}

.color--1 {
  background-color: transparent;
}

/* empty tile */
.color-0 {
  background-color: var(--mid-color);
}

/* impassable tile */
.color-1 {
  background-color: rgb(77, 75, 71);
}

/* metal tile */
.color-2 {
  background-color: #f9b697;
}

/* red color tiles */
.color--10 {
  background-color: rgb(255, 100, 100);
}

.color--20 {
  background-color: rgb(255, 90, 90);
}

.color--30 {
  background-color: rgb(255, 80, 80);
}

.color--40 {
  background-color: rgb(255, 70, 70);
}

.color--50 {
  background-color: rgb(255, 60, 60);
}

.color--60 {
  background-color: rgb(255, 50, 50);
}

.color--70 {
  background-color: rgb(255, 40, 40);
}

.color--80 {
  background-color: rgb(255, 30, 30);
}

.color--90 {
  background-color: rgb(255, 20, 20);
}

.color--100 {
  background-color: rgb(255, 10, 10);
}

/* blue color tiles */
.color-10 {
  background-color: rgb(135, 195, 255);
}

.color-20 {
  background-color: rgb(120, 180, 255);
}

.color-30 {
  background-color: rgb(105, 165, 255);
}

.color-40 {
  background-color: rgb(90, 150, 255);
}

.color-50 {
  background-color: rgb(75, 135, 255);
}

.color-60 {
  background-color: rgb(60, 110, 255);
}

.color-70 {
  background-color: rgb(45, 95, 255);
}

.color-80 {
  background-color: rgb(30, 80, 255);
}

.color-90 {
  background-color: rgb(15, 65, 255);
}

.color-100 {
  background-color: rgb(0, 50, 255);
}

/* terraformed tile red */
/* .color-4 {
  background-color: rgb(250, 60, 60);
} */

/* terraformed tile blue */
/* .color-3 {
  background-color: rgb(100, 150, 255);
} */

/* trail empty color */
.color-5 {
  background-color: none;
}

.grid-square .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;
  top: 0%;
  /* margin-left: calc(var(--tile-size)/2); */

  /* Position the tooltip */
  position: relative;
  z-index: 2;
}

.grid-square:hover .tooltiptext {
  visibility: visible;
}
