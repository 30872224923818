.side-panel {
  background-color: var(--mid-color);
  flex-grow: 0;
  width: 30vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

h1 {
  font-family: 'Earth Orbiter', sans-serif;
  color: var(--dark-color);
  font-size: 5vmin;
  margin: 5vh 1vw 2vh 1vw;
}

h2 {
  font-family: 'Earth Orbiter', sans-serif;
  color: var(--dark-color);
  font-size: 3.2vmin;
  margin: 0;
}

p {
  font-size: 2.3vmin;
}

/* Next and Previous Buttons */
.arrow {
  width: 4vmin;
  height: 4vmin;
  border-radius: 50%;
  border: none;
  margin: 1vmin;

  font-size: 2vmin;
  background-color: var(--dark-color);
  color: var(--light-color);

  transform: scale(0.9);
  transition-duration: 200ms;
  transition-property: background-color;
}

.arrow:hover {
  background: var(--dark-accent-color);
  transform: scale(1);
}

.arrow:active {
  background-color: var(--mid-color);
}

.arrow:disabled {
  background-color: var(--dark-color);
  border: 1px solid var(--light-color);
  opacity: 70%;
}

.arrow:disabled:hover {
  transform: scale(0.9);
}

/* Speed Selector */
.speed-select.MuiOutlinedInput-root {
  color: var(--light-color);
  background: var(--dark-color);
  width: 10vh;
  height: 6vh;
  font-size: 2vmin;
}

.speed-select.MuiOutlinedInput-root:hover,
.speed-select.MuiOutlinedInput-root.Mui-focused {
  background: var(--dark-accent-color);
}

.speed-select.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--dark-color);
}

.speed-select.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--dark-color);
}

.speed-select.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--dark-color);
}

.speed-select.MuiOutlinedInput-root.Mui-disabled {
  background: var(--dark-color);
  border: none;
  opacity: 70%;
}

.MuiSvgIcon-root.Mui-disabled {
  color: var(--light-color);
  opacity: 70%;
}

.MuiSvgIcon-root {
  color: var(--light-color);
}

label {
  margin: 0.5em;
}

input[type='number'] {
  width: 2.7em;
  height: 1.8em;
  margin: 0.3em;
  background-color: var(--light-color);
  border: 1px solid var(--dark-color);
  border-radius: 0.3em;
}
input[type='radio'] {
  accent-color: var(--dark-color);
  margin-right: 1vw;
  scale: 2;
}
.radio-container {
  margin-top: 1vh;
  margin-left: 8vw;
  margin-bottom: 1vh;
}
.switches {
  margin-left: 9vw;
  margin-bottom: 1vh;
}

input[type='submit'],
button {
  margin-bottom: 1.6em;
  margin-top: 0.5em;
  margin-left: 1em;
  padding: 0.3em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 0.3em;
  border: none;
  background-color: var(--dark-color);
  color: white;
}

input[type='submit']:hover,
button:hover {
  background-color: var(--dark-accent-color);
}
