/* Troop Types */

.circle {
  width: 70%;
  height: 70%;
  border-radius: 50%;
  border: 1px solid black;
  box-shadow: none;
}

.b-KNIGHT {
  background-color: blue;
}

.r-KNIGHT {
  background-color: red;
}
