.side-panel {
  background-color: var(--mid-color);
  flex-grow: 0;
  width: 30vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Don't want clicking on specific locations bc of error */
MuiSlider-root {
  pointer-events: none !important;
}

MuiSlider-thumb {
  pointer-events: all !important;
}

#troop-label {
  margin: 5%;
  border: solid #faf3d9 5px;
  width: 80%;
}

h1 {
  font-family: 'Earth Orbiter', sans-serif;
  color: var(--dark-color);
  font-size: 5vmin;
  margin: 5vh 1vw 3vh 1vw;
}

h2 {
  font-family: 'Roboto', 'Helveltica Nue', sans-serif;
  color: var(--dark-color);
  font-size: 2vmin;
}

.info {
  font-family: 'Roboto', 'Helveltica Nue', sans-serif;
  color: var(--dark-color);
  font-size: 2vmin;
  margin: 1vh 2vw 1vh 3vw;
  float: left;
}

.info.win {
  margin: 1vh 1vw 1vh 1vw;
}

.info.stats {
  font-family: 'Earth Orbiter', 'Helveltica Nue', sans-serif;
  font-size: 2.5vmin;
  margin: 1vmin 0 1vmin 0;
}

.hori-container {
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.vert-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hori-container.graph div {
  margin: 2vmin 0vmin 2vmin 0vmin;
  justify-content: center;
  flex-basis: 50%;
  flex-wrap: wrap;
}

.vert-container.graph {
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}

.info.blue {
  color: var(--light-color);
  font-weight: bold;
  border-width: 2px;
  border-radius: 2px;
  border-style: solid;
  border-color: var(--blue-color);
  padding-left: 5px;
  padding-right: 5px;
  background-color: var(--blue-color);
}

.info.red {
  color: var(--light-color);
  font-weight: bold;
  border-width: 2px;
  border-radius: 2px;
  border-style: solid;
  border-color: var(--red-color);
  padding-left: 5px;
  padding-right: 5px;
  background-color: var(--red-color);
}

.togglelabel {
  font-family: 'Roboto', 'Helveltica Nue', sans-serif;
  color: var(--dark-color);
  font-size: 2vmin;
}

p {
  margin: 0;
}

/* File upload button */
.file-upload {
  width: 25vw;
  margin: 0 2vw 2vh 2vw;
  background: var(--light-color);
  border-radius: 5px;
  border: 2px solid var(--dark-color);
  font-family: 'Roboto', 'Helveltica Nue', sans-serif;
  font-size: 1.6vmin;
}

.file-upload::file-selector-button {
  font-weight: normal;
  color: var(--light-color);
  background: var(--dark-color);
  border: 1px solid var(--dark-color);
  padding: 1vmin;
  cursor: pointer;
}

.file-upload::file-selector-button:hover {
  color: var(--light-color);
  background: var(--dark-accent-color);
}

/* Slider */
.slider {
  color: var(--dark-color);
  width: 25vw;
  height: 3vh;
  border-radius: 3px;
  padding: 0;
  margin: 1vh 0 1vh 0;
}

.slider:hover {
  color: var(--dark-color);
}

.MuiSlider-rail {
  color: var(--dark-color);
}

.MuiSlider-mark {
  color: var(--dark-color);
}

.MuiSlider-thumb {
  height: 3vmin;
  width: 1vmin;
  border-radius: 1px;
  background: var(--light-color);
}

.MuiSlider-thumb:hover {
  box-shadow: 0 0 0 6px #6639263b;
}

/* Icon play button */
.play-icon.MuiSvgIcon-root {
  color: var(--light-color);
  width: 4vmin;
  height: 4vmin;
}

.play-control.MuiButtonBase-root {
  background: var(--dark-color);
  transform: scale(0.9);
  width: 8vmin;
  height: 8vmin;
}

.play-control.MuiButtonBase-root:hover {
  background: var(--dark-accent-color);
  transform: scale(1);
}

.play-control.MuiButtonBase-root:active {
  background: none;
}

.play-control.MuiButtonBase-root:disabled {
  background: var(--dark-color);
  border: 1px solid var(--light-color);
  opacity: 70%;
}

/* Next and Previous Buttons */
.arrow {
  width: 4vmin;
  height: 4vmin;
  border-radius: 50%;
  border: none;
  margin: 1vmin;

  font-size: 2vmin;
  background-color: var(--dark-color);
  color: var(--light-color);

  transform: scale(0.9);
  transition-duration: 200ms;
  transition-property: background-color;
}

.arrow:hover {
  background: var(--dark-accent-color);
  transform: scale(1);
}

.arrow:active {
  background-color: var(--mid-color);
}

.arrow:disabled {
  background-color: var(--dark-color);
  border: 1px solid var(--light-color);
  opacity: 70%;
}

.arrow:disabled:hover {
  transform: scale(0.9);
}

/* Speed Selector */
.speed-select.MuiOutlinedInput-root {
  color: var(--light-color);
  background: var(--dark-color);
  width: 10vh;
  height: 6vh;
  font-size: 2vmin;
}

.speed-select.MuiOutlinedInput-root:hover,
.speed-select.MuiOutlinedInput-root.Mui-focused {
  background: var(--dark-accent-color);
}

.speed-select.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--dark-color);
}

.speed-select.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--dark-color);
}

.speed-select.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--dark-color);
}

.speed-select.MuiOutlinedInput-root.Mui-disabled {
  background: var(--dark-color);
  border: none;
  opacity: 70%;
}

.MuiSvgIcon-root.Mui-disabled {
  color: var(--light-color);
  opacity: 70%;
}

.MuiSvgIcon-root {
  color: var(--light-color);
}

.toggle-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 7vw;
  margin-top: 1vh;
}
